import React from "react";
import { Card, Button, Table, message } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import { firestore } from "../../../firebase/firebase";
import IntlMessages from "util/IntlMessages";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

class Services extends React.Component {
  state = {
    warning: false,
    selectedServoceKey: "",
    services: [],
  };
  unsubscribeGetServices = null;

  async componentDidMount() {
    this.mounted = true;
    this.unsubscribeGetServices = await firestore
      .collection("services")
      .onSnapshot((snapshots) => {
        let services = [];
        let teamId = JSON.parse(localStorage.getItem("user_info")).team;
        snapshots.forEach((snapshot) => {
          if (snapshot.data().team_id === teamId)
            services.push({ ...snapshot.data(), key: snapshot.id });
        });
        if (this.mounted) {
          this.setState({ services: [...services] });
        }
      });
  }
  componentWillUnmount() {
    this.mounted = false;
    if (this.unsubscribeGetServices) this.unsubscribeGetServices();
  }
  onAddClicked = () => {
    this.props.history.push("/main/services/add");
  };
  onEditClicked = (key) => {
    this.props.history.push("/main/services/" + key);
  };
  onDeleteClicked = (key) => {
    this.setState({ warning: true });
    this.setState({ selectedServiceKey: key });
  };
  onConfirmDelete = async (key) => {
    this.setState({ warning: false });
    this.setState({ selectedServiceKey: "" });
    let user = localStorage.getItem("user_id");
    await firestore.collection("services").doc(key).delete();
    let user_services = [];
    await firestore
      .collection("customers")
      .doc(user)
      .onSnapshot((snapshot) => {
        let detail = snapshot.data();
        user_services = detail.services.filter((service) => service !== key);
      });

    await firestore
      .collection("customers")
      .doc(user)
      .update({ services: user_services });

    message.success("This service has been successfully deleted.");
  };
  onCancelDelete = () => {
    this.setState({ warning: false });
    this.setState({ selectedServiceKey: "" });
  };
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
      },
      {
        title: "Req/sec",
        dataIndex: "req",
        key: "req",
        width: 70,
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
      },
      {
        title: "Authentication",
        dataIndex: "authentication",
        key: "authentication",
      },
      {
        title: "Ingress",
        dataIndex: "ingress_url",
        key: "ingress",
      },
      {
        title: "Last deployed",
        dataIndex: "last_deployed",
        key: "last_deployed",
      },
      {
        title: "Deployed by",
        dataIndex: "deployed_email",
        key: "deployed_email",
      },
      {
        title: "Action",
        key: "action",
        width: 160,
        class: "gx-text-center",
        fixed: "right",
        render: (text, record) => (
          <span>
            <Button
              type="success"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => this.onEditClicked(record.key)}
            />
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => this.onDeleteClicked(record.key)}
            />
          </span>
        ),
      },
    ];
    return (
      <Card title="Services">
        <Button
          type="success"
          icon={<PlusCircleOutlined />}
          onClick={this.onAddClicked}
        >
          Add
        </Button>
        <Table
          className="gx-table-responsive"
          bordered={true}
          rowSelection
          columns={columns}
          dataSource={this.state.services}
        />
        <SweetAlert
          show={this.state.warning}
          warning
          showCancel
          confirmBtnText={<IntlMessages id="sweetAlerts.yesDeleteIt" />}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={<IntlMessages id="sweetAlerts.areYouSure" />}
          onConfirm={() => this.onConfirmDelete(this.state.selectedServiceKey)}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </Card>
    );
  }
}

export default Services;
