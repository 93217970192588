import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import Services from "./services";
import AddService from "./services/AddService";
import EditService from "./services/EditService";
import Subscriptions from "./subscriptions";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard} />
    <Route path={`${match.url}/services/add`} component={AddService} />
    <Route path={`${match.url}/services/:id`} component={EditService} />
    <Route path={`${match.url}/services`} component={Services} />
    <Route path={`${match.url}/subscriptions`} component={Subscriptions} />
  </Switch>
);

export default Main;
