import firebase from "firebase";
import mixpanel from "mixpanel-browser";
// Initialize Firebase
const config = {
  apiKey: "AIzaSyA1tklQHLz-_Q35Qo8VYr_pzd2It4KZUgU",
  authDomain: "serverless-299405.firebaseapp.com",
  databaseURL: "https://serverless-299405.firebaseio.com",
  projectId: "serverless-299405",
  storageBucket: "serverless-299405.appspot.com",
  messagingSenderId: "425994801895",
  appId: "1:425994801895:web:5b31de08dae427cd7e4842",
  measurementId: "G-PTNBFX1STT",
};

firebase.initializeApp(config);
firebase.analytics();
mixpanel.init("70ed4ecceef71d9a9fde8780c0fcf126");
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();
const firestore = firebase.firestore();
const analytics = firebase.analytics();
const firebaseApp = firebase.app();

export {
  analytics,
  database,
  firestore,
  auth,
  firebaseApp,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
};
