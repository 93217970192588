import React, { useState, useEffect } from "react";
import { Button, Select, Form, Input, Checkbox } from "antd";
import {
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { hideMessage, showAuthLoader, userSignUp } from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";

import { firestore } from "../firebase/firebase";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;
const Option = Select.Option;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [isFreeSubscription, setSubscriptionType] = useState(true);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        await firestore
          .collection("products")
          .get()
          .then((products) => {
            let res = [];
            products.forEach(async function (prod) {
              res.push({ ...prod.data(), key: prod.id });
            });
            res.sort(
              (a, b) => a.stripe_metadata_priority - b.stripe_metadata_priority
            );
            setSubscriptionOptions(res);
          });
      } catch (err) {
        console.error(err);
      }
    };
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/main/services");
    }
  }, [dispatch, history, showMessage, authUser]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignUp(values));
  };

  const onSubscriptionChange = async (value) => {
    await firestore
      .collection("products")
      .doc(value)
      .collection("prices")
      .get()
      .then((prices) => {
        let res = [];
        prices.forEach((price) => {
          res.push({ ...price.data(), key: price.id });
        });
        let price =
          res[0].tiers !== null ? res[0].tiers[0].flat_amount / 100 : 0;
        setSubscriptionType(price === 0);
      });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content gx-m-auto">
            <div className="gx-text-center gx-mb-4">
              <img
                src={require("../assets/images/logo.png")}
                alt="Serverl3ss"
                title="Serverl3ss"
              ></img>
            </div>
            <Form
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem
                rules={[
                  {
                    required: true,
                    message: "Please select your subscription!",
                  },
                ]}
                name="subscription"
                id="subscription"
              >
                <Select
                  showSearch
                  placeholder="Subscription"
                  onChange={onSubscriptionChange}
                >
                  {subscriptionOptions.map((sub) => (
                    <Option key={sub.key} value={sub.key}>
                      {sub.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              {!isFreeSubscription ? (
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please input your company name!",
                    },
                  ]}
                  id="team"
                  name="team"
                >
                  <Input
                    prefix={
                      <HomeOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Team"
                  />
                </FormItem>
              ) : (
                <></>
              )}
              <FormItem
                rules={[
                  { required: true, message: "Please input your full name!" },
                ]}
                id="full_name"
                name="full_name"
              >
                <Input
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Full Name"
                />
              </FormItem>
              <FormItem
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
                name="email"
                id="email"
              >
                <Input
                  prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Email"
                />
              </FormItem>
              <FormItem
                rules={[
                  { required: true, message: "Please input your Password!" },
                  {
                    min: 6,
                    message: "Password should be at least 6 characters!",
                  },
                ]}
                name="password"
                id="password"
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Password"
                />
              </FormItem>
              <FormItem
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            "Please accept our Terms and Conditions!"
                          ),
                  },
                ]}
                name="agreement"
                valuePropName="checked"
                id="agreement"
              >
                <Checkbox>
                  <IntlMessages id="appModule.iAccept" />{" "}
                  <span className="gx-signup-form-forgot gx-link">
                    <IntlMessages id="appModule.termAndCondition" />
                  </span>
                </Checkbox>
              </FormItem>
              <FormItem>
                <div style={{ textAlign: "center" }}>
                  <Button type="success" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                  <Button
                    type="default"
                    className="gx-mb-0"
                    htmlType="button"
                    onClick={() => {
                      props.history.push("/signin");
                    }}
                  >
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </div>
              </FormItem>
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
