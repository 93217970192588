import React from "react";
import { Row } from "antd";

import Auxiliary from "util/Auxiliary";
const Dashboard = () => {
  return (
    <Auxiliary>
      <Row></Row>
    </Auxiliary>
  );
};

export default Dashboard;
