import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, message } from "antd";
import { useHistory } from "react-router-dom";
import {
  hideMessage,
  showAuthLoader,
  userResetPassword,
  hideResetSuccessMessage,
} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const {
    loader,
    alertMessage,
    showMessage,
    authUser,
    resetSuccessMessage,
    showResetSuccessMessage,
  } = useSelector(({ auth }) => auth);
  const history = useHistory();

  const oobCode = new URLSearchParams(props.location.search).get("oobCode");
  const continueUrl = new URLSearchParams(props.location.search).get(
    "continueUrl"
  );
  const email = continueUrl.split("?email=")[1];

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (showResetSuccessMessage) {
      setTimeout(() => {
        dispatch(hideResetSuccessMessage());
        history.push("/signin");
      }, 500);
    }
    if (authUser !== null) {
      history.push("/main/services");
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    values = { ...values, code: oobCode, email: email };
    dispatch(userResetPassword(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content gx-m-auto">
            <div className="gx-login-header">
              <img
                src={require("assets/images/logo.png")}
                alt="Serverl3ss"
                title="Serverl3ss"
              />
            </div>
            <div className="gx-mb-4">
              <h2>Reset Password</h2>
              <p>
                <IntlMessages id="appModule.enterPasswordReset" />
              </p>
            </div>

            <Form
              className="gx-signin-form gx-form-row0"
              name="password"
              id="password"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{ remember: true }}
            >
              <FormItem
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                name="password"
                id="password"
              >
                <Input.Password
                  className="gx-input-lineheight"
                  placeholder="Password"
                />
              </FormItem>

              <FormItem
                rules={[
                  { required: true, message: "Please confirm your password!" },
                ]}
                name="confirm"
                id="confirm"
              >
                <Input.Password
                  className="gx-input-lineheight"
                  placeholder="Confirm Password"
                />
              </FormItem>

              <FormItem className="gx-text-center">
                <Button type="success" htmlType="submit">
                  <IntlMessages id="app.userAuth.reset" />
                </Button>
              </FormItem>
            </Form>
          </div>
          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
          {showResetSuccessMessage
            ? message.success(resetSuccessMessage.toString())
            : null}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
