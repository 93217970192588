import React, { useEffect } from "react";
import { Button, Checkbox, Input, message, Form } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  doAutoSignin,
} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const SignIn = () => {
  const dispatch = useDispatch();
  const {
    loader,
    alertMessage,
    showMessage,
    authUser,
    userEmail,
    userNewPassword,
  } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (
      localStorage.getItem("stripe_session_id") &&
      localStorage.getItem("user_id")
    ) {
      dispatch(doAutoSignin());
    }
  }, [dispatch]);
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/main/services");
    }
  }, [dispatch, history, showMessage, authUser]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content gx-m-auto">
            <div className="gx-text-center gx-mb-4">
              <img
                src={require("../assets/images/logo.png")}
                alt="Serverl3ss"
                title="Serverl3ss"
              ></img>
            </div>
            <Form
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
                name="email"
                id="email"
                initialValue={userEmail}
              >
                <Input
                  prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                name="password"
                id="password"
                initialValue={userNewPassword}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
                <Link className="gx-login-form-forgot" to="/forgot-password">
                  Forgot password
                </Link>
              </Form.Item>
              <Form.Item>
                <div style={{ textAlign: "center" }}>
                  <Button type="success" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  <Button
                    type="default"
                    className="gx-mb-0"
                    htmlType="button"
                    onClick={() => history.push("/signup")}
                  >
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
