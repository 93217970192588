import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

import {
  analytics,
  auth,
  firestore,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from "../../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  FORGOTPASSWORD_USER,
  RESETPASSWORD_USER,
} from "constants/ActionTypes";
import {
  hideMessage,
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
  userResetPasswordSuccess,
} from "../actions/Auth";
import mixpanel from "mixpanel-browser";

require("dotenv").config();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const getUserByUID = async (uid) => {
  let user = await firestore
    .collection("customers")
    .doc(uid)
    .get()
    .then((snapshot) => snapshot.data());
  return user;
};

const checkTeamUnique = async (teamName) => {
  let url =
    "https://us-central1-serverless-299405.cloudfunctions.net/checkTeamUnique";
  let response = await axios.post(url, { team: teamName });
  return response.data;
};

const saveCustomerInfo = async (customerInfo) => {
  console.log("save customer info");
  const customerDocRef = firestore
    .collection("customers")
    .doc(customerInfo.cid);
  await customerDocRef.get().then(async (docSnapshot) => {
    if (docSnapshot.exists) {
      await customerDocRef.update({
        full_name: customerInfo.full_name,
        email: customerInfo.email,
        team: customerInfo.team,
      });
    } else {
      await customerDocRef.set({
        full_name: customerInfo.full_name,
        email: customerInfo.email,
        team: customerInfo.team,
      });
    }
  });
};

const getSubscriptionPrice = async (subscriptionInfo) => {
  console.log("get subscription price");
  const querySnapshot = await firestore
    .collection("products")
    .doc(subscriptionInfo.subscription)
    .collection("prices")
    .limit(1)
    .get();
  const doc = querySnapshot.docs[0];
  let result = { ...doc.data(), id: doc.id };
  return result;
};

const stripeCheckout = async (customerInfo) => {
  console.log("stripe checkout");
  const docRef = await firestore
    .collection("customers")
    .doc(customerInfo.cid)
    .collection("checkout_sessions")
    .add({
      price: customerInfo.price,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot(async (snap) => {
    const { error, sessionId } = snap.data();
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`);
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout

      localStorage.setItem("stripe_session_id", sessionId);
      // Init Stripe
      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId });
    }
  });
};

const forgotUserPasswordRequest = async (email) => {
  var actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: "http://localhost:3001/signin?email=" + btoa(email),
    handleCodeInApp: false,
  };
  await auth
    .sendPasswordResetEmail(email, actionCodeSettings)
    .then((authUser) => authUser)
    .catch((error) => error);
};
const resetUserPasswordRequest = async (code, password) => {
  await auth
    .confirmPasswordReset(code, password)
    .then(() => {
      console.log("Password has been changed successfully.");
    })
    .catch((error) => error);
};
function* createUserWithEmailPassword({ payload }) {
  const { team, full_name, email, password, subscription } = payload;
  try {
    let isValidTeam = true;
    if (team) {
      let isTeamUnique = yield call(checkTeamUnique, team);
      if (isTeamUnique === "success") isValidTeam = true;
      else isValidTeam = false;
    }
    if (!isValidTeam)
      yield put(showAuthMessage("Team already exists. Please try again."));
    else {
      const signUpUser = yield call(
        createUserWithEmailPasswordRequest,
        email,
        password
      );
      if (signUpUser.message) {
        yield put(showAuthMessage(signUpUser.message));
      } else {
        mixpanel.track("Register_email_pass", {
          user: signUpUser.user.uid,
          team: signUpUser.user.team,
        });
        analytics.logEvent("Register_email_pass");

        yield call(saveCustomerInfo, {
          cid: signUpUser.user.uid,
          full_name: full_name,
          email: email,
          team: team || "",
        });

        //user's subscription price
        let price = yield call(getSubscriptionPrice, {
          subscription: subscription,
        });

        const user = yield call(getUserByUID, signUpUser.user.uid);
        if (user) {
          localStorage.setItem("user_id", signUpUser.user.uid);
          localStorage.setItem("user_info", JSON.stringify(user));

          if (
            (price.billing_scheme === "tiered" && price.tiers !== null) ||
            (price.billing_scheme === "per_unit" && price.unit_amount > 0)
          ) {
            //stripe checkout with price
            yield call(stripeCheckout, {
              cid: signUpUser.user.uid,
              price: price.id,
            });
          } else {
            yield put(hideMessage());
            yield put(userSignUpSuccess(signUpUser.user.uid));
          }
        }
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem("user_id", signInUser.user.uid);
      mixpanel.track("Login_email_pass", {
        user: signInUser.user.uid,
        team: signInUser.user.team,
      });
      analytics.logEvent("Login_email_pass");
      let user = yield call(getUserByUID, signInUser.user.uid);
      if (user) localStorage.setItem("user_info", JSON.stringify(user));
      yield put(userSignInSuccess(signInUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_info");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* forgotUserPassword({ payload }) {
  const { email } = payload;
  try {
    const signInUser = yield call(forgotUserPasswordRequest, email);
    if (signInUser) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      yield put(hideMessage());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* resetUserPassword({ payload }) {
  const { code, email, password } = payload;
  try {
    yield call(resetUserPasswordRequest, code, password);
    const msg = "Password has been successfully reset.";
    yield put(
      userResetPasswordSuccess({ email: email, password: password, msg: msg })
    );
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* forgotPasswordUser() {
  yield takeEvery(FORGOTPASSWORD_USER, forgotUserPassword);
}

export function* resetPasswordUser() {
  yield takeEvery(RESETPASSWORD_USER, resetUserPassword);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
    fork(forgotPasswordUser),
    fork(resetPasswordUser),
  ]);
}
