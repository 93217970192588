import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, message } from "antd";
import { useHistory } from "react-router-dom";
import {
  hideMessage,
  showAuthLoader,
  userForgotPassword,
} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/main/services");
    }
  });

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userForgotPassword(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content gx-m-auto">
            <div className="gx-login-header">
              <img
                src={require("../assets/images/logo.png")}
                alt="Serverl3ss"
                title="Serverl3ss"
              ></img>
            </div>
            <div className="gx-mb-4">
              <h2>Forgot Your Password ?</h2>
              <p>
                <IntlMessages id="app.userAuth.forgot" />
              </p>
            </div>

            <Form
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your E-mail!",
                  },
                ]}
                name="email"
                id="email"
              >
                <Input
                  className="gx-input-lineheight"
                  placeholder="E-Mail Address"
                />
              </FormItem>

              <FormItem className="gx-text-center">
                <Button type="success" htmlType="submit">
                  <IntlMessages id="app.userAuth.send" />
                </Button>
              </FormItem>
            </Form>
          </div>
          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
