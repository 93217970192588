import React from "react";
import {
  Divider,
  Form,
  Card,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Radio,
  Collapse,
  message,
} from "antd";
import { firestore } from "../../../firebase/firebase";
import { DeleteOutlined } from "@ant-design/icons";

const { Option, OptGroup } = Select;
const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;

class AddService extends React.Component {
  state = {
    template: "",
    container_names: [],
    templates: [],
    service_key: "",
  };
  formRef = React.createRef();
  unsubscribeGetTemplates = null;

  componentDidMount() {
    this.mounted = true;
    this.unsubscribeGetTemplates = firestore
      .collection("templates")
      .onSnapshot((snapshots) => {
        let templates = snapshots.docs.map((doc) => {
          return { ...doc.data(), key: doc.id };
        });
        if (this.mounted) {
          this.setState({ templates: [...templates] });
        }
      });
  }
  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeGetTemplates();
  }

  onFinish = async (values) => {
    let dt = new Date();
    let year = dt.getFullYear();
    let month = dt.getMonth() + 1;
    let day = dt.getDate();
    let hour = dt.getHours();
    let minute = dt.getMinutes();
    let second = dt.getSeconds();
    let now =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day) +
      " " +
      (hour < 10 ? "0" + hour : hour) +
      ":" +
      (minute < 10 ? "0" + minute : minute) +
      ":" +
      (second < 10 ? "0" + second : second);
    let user = localStorage.getItem("user_id");
    let teamId = JSON.parse(localStorage.getItem("user_info")).team;
    let userEmail = JSON.parse(localStorage.getItem("user_info")).email;
    let ingressPorts = {};
    values.ingress_ports.forEach((port) => {
      ingressPorts[port.external_port] = port.container_port;
    });
    let formData = {
      ...values,
      deployed_by: user,
      deployed_email: userEmail,
      team_id: teamId,
      req: 0,
      ingress_url: "myservice.serverl3ss.com",
      ingress_ports: JSON.stringify(ingressPorts),
      last_deployed: now,
    };
    let newService = await firestore.collection("services").add(formData);
    this.setState({ service_key: newService.id });
    let userServices = [];
    userServices = await firestore
      .collection("customers")
      .doc(user)
      .get()
      .then((snapshot) => {
        let services = snapshot.data().services ? snapshot.data().services : [];
        return services;
      });
    console.log(userServices);

    userServices.push(newService.id);
    firestore
      .collection("customers")
      .doc(user)
      .update({ services: userServices })
      .then((snapshot) => {
        console.log(
          "New service id(" +
            this.state.service_key +
            ") has been added to user detail."
        );
        message.success("This service has been successfully saved.");
        this.props.history.push("/main/services");
      });
  };
  onFinishFailed = ({ errorFields }) => {
    console.log(errorFields);
  };
  onCancel = () => {
    this.props.history.push("/main/services");
  };
  onTemplateChanged = (value) => {
    this.setState({ template: value });
    let template = this.state.templates.filter(
      (temp) => temp.template === value
    );
    if (template.length > 0) {
      let containers = template[0].containers;
      let containerNames = [];
      containers.forEach((cont, index) => {
        containerNames[index] = cont.container_name;
      });
      this.formRef.current.setFieldsValue(template[0]);
      this.setState({ container_names: [...containerNames] });
    }
  };
  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };
    return (
      <Card title="Add service">
        <Form
          ref={this.formRef}
          name="add_service"
          className="ant-advanced-search-form"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 6 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 18 },
          }}
        >
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Form.Item
                name="name"
                label="Service Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Service Name" />
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Form.Item
                name="template"
                label="Template"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue="own service"
              >
                <Select showSearch onChange={this.onTemplateChanged}>
                  <Option value="own service">Add my own service</Option>
                  {this.state.templates.map((temp) => {
                    return (
                      <Option key={temp.key} value={temp.template}>
                        {temp.template}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Form.Item
                name="region"
                label="Region"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue="us-central1 (Iowa)"
              >
                <Select showSearch>
                  <OptGroup label="Americas">
                    <Option value="northamerica-northeast1 (Montreal)">
                      northamerica-northeast1 (Montreal)
                    </Option>
                    <Option value="southamerica-east1 (Sao Paulo)">
                      southamerica-east1 (Sao Paulo)
                    </Option>
                    <Option value="us-central1 (Iowa)">
                      us-central1 (Iowa)
                    </Option>
                    <Option value="us-east1 (South Carolina)">
                      us-east1 (South Carolina)
                    </Option>
                    <Option value="us-east4 (Northern Virginia)">
                      us-east4 (Northern Virginia)
                    </Option>
                    <Option value="us-west1 (Oregon)">us-west1 (Oregon)</Option>
                  </OptGroup>
                  <OptGroup label="Asia Pacific">
                    <Option value="east-asia1 (Taiwan)">
                      east-asia1 (Taiwan)
                    </Option>
                    <Option value="east-asia2 (Hong Kong)">
                      east-asia2 (Hong Kong)
                    </Option>
                    <Option value="east-northeast1 (Tokyo)">
                      east-northeast1 (Tokyo)
                    </Option>
                    <Option value="east-northeast2 (Osaka)">
                      east-northeast2 (Osaka)
                    </Option>
                    <Option value="east-northeast3 (Seoul)">
                      east-northeast3 (Seoul)
                    </Option>
                    <Option value="east-south1 (Mumbai)">
                      east-south1 (Mumbai)
                    </Option>
                    <Option value="east-southeast1 (Singapore)">
                      east-southeast1 (Singapore)
                    </Option>
                    <Option value="east-southeast2 (Jakarta)">
                      east-southeast2 (Jakarta)
                    </Option>
                    <Option value="australia-southeast1 (Sydney)">
                      australia-southeast1 (Sydney)
                    </Option>
                  </OptGroup>
                  <OptGroup label="Europe">
                    <Option value="europe-north1 (Finland)<Yiminghe">
                      europe-north1 (Finland)
                    </Option>
                    <Option value="europe-west1 (Belgium)">
                      europe-west1 (Belgium)
                    </Option>
                    <Option value="europe-west2 (London)">
                      europe-west2 (London)
                    </Option>
                    <Option value="europe-west3 (Frankfurt)">
                      europe-west3 (Frankfurt)
                    </Option>
                    <Option value="europe-west4 (Netherlands)">
                      europe-west4 (Netherlands)
                    </Option>
                    <Option value="europe-west6 (Zurich)">
                      europe-west6 (Zurich)
                    </Option>
                  </OptGroup>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Form.Item
                label="Containers"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Form.List name="containers" initialValue={[""]}>
                  {(fields, { add, remove }) => (
                    <>
                      <Collapse>
                        {fields.map((field, index) => (
                          <Panel
                            header={
                              this.state.container_names[field.name]
                                ? this.state.container_names[field.name]
                                : `Container ${index + 1}`
                            }
                            key={field.key}
                            extra={
                              fields.length > 1 ? (
                                <DeleteOutlined
                                  className="gx-text-red"
                                  onClick={() => remove(field.name)}
                                />
                              ) : (
                                <></>
                              )
                            }
                          >
                            <Divider orientation="left">General</Divider>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "container_name"]}
                                  fieldKey={[field.fieldKey, "container_name"]}
                                  label="Name"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Container name"
                                    onChange={(e) => {
                                      let containerNames = this.state
                                        .container_names;
                                      containerNames[field.name] =
                                        e.target.value;
                                      this.setState({
                                        container_name: containerNames,
                                      });
                                    }}
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "ports"]}
                                  fieldKey={[field.fieldKey, "ports"]}
                                  label="Ports"
                                  initialValue={[]}
                                >
                                  <Select
                                    mode="tags"
                                    placeholder="Ports"
                                  ></Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "image"]}
                                  fieldKey={[field.fieldKey, "image"]}
                                  label="Image URL"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Input placeholder="Container image URL"></Input>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "container_commands"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "container_commands",
                                  ]}
                                  label="Commands"
                                  initialValue={[]}
                                >
                                  <Select
                                    mode="tags"
                                    placeholder="Container Commands"
                                  ></Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row></Row>
                            <Row>
                              <Col span={24}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "volumns"]}
                                  fieldKey={[field.fieldKey, "volumns"]}
                                  label="Volumns"
                                  initialValue={[]}
                                >
                                  <Select
                                    mode="tags"
                                    placeholder="Volumns"
                                  ></Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Divider orientation="left">Capacity</Divider>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "memory_allocated"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "memory_allocated",
                                  ]}
                                  label="Memory allocated"
                                  initialValue="128MiB"
                                >
                                  <Select>
                                    <Option value="128MiB">128MiB</Option>
                                    <Option value="256MiB">256MiB</Option>
                                    <Option value="512MiB">512MiB</Option>
                                    <Option value="1GiB">1GiB</Option>
                                    <Option value="2GiB">2GiB</Option>
                                    <Option value="4GiB">4GiB</Option>
                                    <Option value="8GiB">8GiB</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "cpu_allocated"]}
                                  fieldKey={[field.fieldKey, "cpu_allocated"]}
                                  label="CPU allocated"
                                  initialValue="1"
                                >
                                  <Input placeholder="CPU allocated"></Input>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "request_timeout"]}
                                  fieldKey={[field.fieldKey, "request_timeout"]}
                                  label="Request timeout"
                                  initialValue="300s"
                                >
                                  <Input placeholder="Request timeout"></Input>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "max_requests"]}
                                  fieldKey={[field.fieldKey, "max_requests"]}
                                  label="Maxium requests per container"
                                  initialValue="1"
                                >
                                  <InputNumber
                                    className="gx-w-100"
                                    placeholder="Maxium requests per container"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            {/* <Divider orientation="left">Autoscaling</Divider>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "min_instances"]}
                                  fieldKey={[field.fieldKey, "min_instances"]}
                                  label="Minium number of instances"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                  initialValue="0"
                                >
                                  <InputNumber className="gx-w-100" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "max_instances"]}
                                  fieldKey={[field.fieldKey, "max_instances"]}
                                  label="Maxium number of instances"
                                  initialValue="100"
                                >
                                  <InputNumber className="gx-w-100" />
                                </Form.Item>
                              </Col>
                            </Row> */}
                            <Divider orientation="left">Variables</Divider>
                            <Form.List
                              name={[field.name, "variables"]}
                              initialValue={[""]}
                            >
                              {(variables, { add, remove }) => (
                                <>
                                  {variables.map((variable) => (
                                    <Row key={variable.key}>
                                      <Col span={11}>
                                        <Form.Item
                                          {...variable}
                                          name={[variable.name, "name"]}
                                          label="Name"
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <Input placeholder="e.g. ENV"></Input>
                                        </Form.Item>
                                      </Col>
                                      <Col span={11}>
                                        <Form.Item
                                          {...variable}
                                          name={[variable.name, "value"]}
                                          label="Value"
                                        >
                                          <Input placeholder="e.g. prod"></Input>
                                        </Form.Item>
                                      </Col>
                                      <Col span={2}>
                                        {variables.length > 1 ? (
                                          <DeleteOutlined
                                            className="gx-text-red gx-mt-2"
                                            onClick={() =>
                                              remove(variable.name)
                                            }
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                  <div className="gx-text-right gx-mb-3">
                                    <Button
                                      type="success"
                                      onClick={() => add()}
                                    >
                                      Add variable
                                    </Button>
                                  </div>
                                </>
                              )}
                            </Form.List>
                          </Panel>
                        ))}
                      </Collapse>
                      <div className="gx-text-right gx-mt-3">
                        <Button type="success" onClick={() => add()}>
                          Add container
                        </Button>
                      </div>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </div>
          </Col>
          {/* <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Form.Item name="ingress" label="Ingress" initialValue="ALL">
                <RadioGroup>
                  <Radio style={radioStyle} value="ALL">
                    Allow all traffic
                  </Radio>
                  <Radio
                    style={radioStyle}
                    value="INTERNAL_AND_CLOUD_LOAD_BALANCING"
                    disabled={true}
                  >
                    Allow internal traffic and traffic from Cloud Load Balancing
                  </Radio>
                  <Radio style={radioStyle} value="INTERNAL" disabled={true}>
                    Allow internal traffic only
                  </Radio>
                </RadioGroup>
              </Form.Item>
            </div>
          </Col> */}
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Form.Item label="Ingress Ports">
                <Form.List name="ingress_ports" initialValue={[""]}>
                  {(ingress_ports, { add, remove }) => (
                    <>
                      {ingress_ports.map((port) => (
                        <Row key={port.key}>
                          <Col span={11}>
                            <Form.Item
                              {...port}
                              name={[port.name, "external_port"]}
                              label="External port"
                            >
                              <Input placeholder="External Port"></Input>
                            </Form.Item>
                          </Col>
                          <Col span={11}>
                            <Form.Item
                              {...port}
                              name={[port.name, "container_port"]}
                              label="Container port"
                            >
                              <Input placeholder="Container Port"></Input>
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            {ingress_ports.length > 1 ? (
                              <DeleteOutlined
                                className="gx-text-red gx-mt-2"
                                onClick={() => remove(port.name)}
                              />
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      ))}
                      <div className="gx-text-right gx-mb-3">
                        <Button type="success" onClick={() => add()}>
                          Add port
                        </Button>
                      </div>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Form.Item
                name="authentication"
                label="Authentication"
                initialValue="allowed"
              >
                <RadioGroup>
                  <Radio style={radioStyle} value="allowed">
                    Allow unauthenticated invocations
                  </Radio>
                  <Radio style={radioStyle} value="disallowed">
                    Require authentication
                  </Radio>
                </RadioGroup>
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Form.Item name="owner" label="Owner" initialValue="user">
                <RadioGroup>
                  <Radio style={radioStyle} value="user">
                    Me
                  </Radio>

                  {JSON.parse(localStorage.getItem("user_info")).team !== 0 ? (
                    <Radio style={radioStyle} value="team">
                      Team
                    </Radio>
                  ) : (
                    <></>
                  )}
                </RadioGroup>
              </Form.Item>
            </div>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className="gx-form-row0">
              <Button type="danger" htmlType="submit">
                Create
              </Button>
              <Button onClick={this.onCancel}>Cancel</Button>
            </div>
          </Col>
        </Form>
      </Card>
    );
  }
}

export default AddService;
