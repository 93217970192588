import React from "react";
import { Card, Button, Table, Modal, Input, message } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "util/IntlMessages";

import { firestore, firebaseApp } from "../../../firebase/firebase";
class Subscriptions extends React.Component {
  state = {
    subscriptions: [],
    userSubscription: { name: "", price: 0 },
    isInvitePopupVisible: false,
    isConfirmRemovePopupVisible: false,
    inviteEmail: "",
    teamUsers: [],
    teamUsersLimit: 0,
    selectedMember: {},
    ownerId: localStorage.getItem("user_id"),
    ownerSubscription: "",
  };

  unsubscribeGetUserSubscription = null;
  unsubscribeGetPrice = null;
  unsubscribeGetTeamUsers = null;

  async componentDidMount() {
    this.mounted = true;

    await this.onGetUserSubscriptionDetail();
    //await this.onGetUserTeamMembers();
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.unsubscribeGetPrice) this.unsubscribeGetPrice();
    if (this.unsubscribeGetTeamUsers) this.unsubscribeGetTeamUsers();
  }

  onGetUserSubscriptionDetail = async () => {
    this.unsubscribeGetUserSubscriptionDetail = firestore
      .collection("customers")
      .doc(this.state.ownerId)
      .collection("subscriptions")
      .where("status", "==", "active")
      .limit(1)
      .onSnapshot(async (snapshots) => {
        if (!snapshots.empty) {
          let subscription = snapshots.docs[0].data();
          let productRef = subscription.product;
          let priceRef = subscription.price;
          let price = 0;
          let limit = 0;
          let productData = await productRef
            .get()
            .then((childSnapshot) => childSnapshot.data());
          let priceData = await priceRef
            .get()
            .then((childSnapshot) => childSnapshot.data());

          if (priceData.tiers === null) {
            price = priceData.unit_amount / 100;
            limit = priceData.interval_count;
          } else {
            price = priceData.tiers[0].flat_amount / 100;
            limit = priceData.tiers[0].up_to;
          }

          if (this.mounted) {
            let uSubscriptions = [];
            let uSubscription = {
              ...productData,
              price: price,
              key: productRef.id,
            };
            uSubscriptions.push(uSubscription);
            this.setState({
              ownerSubscription: productRef.id,
              teamUsersLimit: limit,
              userSubscription: uSubscription,
              subscriptions: [...uSubscriptions],
            });
          }

          this.unsubscribeGetTeamUsers = firestore
            .collection("customers")
            .doc(this.state.ownerId)
            .collection("team_members")
            .doc(productRef.id)
            .collection("members")
            .onSnapshot((snapshots) => {
              let members = [];
              snapshots.docs.forEach((snapshot) => {
                members.push({ ...snapshot.data(), key: snapshot.id });
              });
              if (this.mounted) {
                this.setState({ teamUsers: [...members] });
              }
            });
        } else {
          let uSubscription = {
            key: 0,
            name: "Free",
            price: 0,
          };
          let uSubscriptions = [];
          uSubscriptions.push(uSubscription);
          if (this.mounted) {
            this.setState({ subscriptions: [...uSubscriptions] });
          }
        }
      });
  };

  setisInvitePopupVisible = (status) => {
    this.setState({ isInvitePopupVisible: status });
  };
  onInviteEmailChanged = (e) => {
    this.setState({ inviteEmail: e.target.value });
  };
  onSendInvitation = async () => {
    const {
      ownerId,
      ownerSubscription,
      teamUsersLimit,
      inviteEmail,
    } = this.state;
    let members = this.state.teamUsers;
    let isValid = true;
    if (members.length >= teamUsersLimit) {
      isValid = false;
      message.error(
        "Maximum number of seats reached. Please purchase more seats or update your subscription."
      );
    } else {
      await firestore
        .collection("customers")
        .doc(ownerId)
        .collection("team_members")
        .doc(ownerSubscription)
        .collection("members")
        .where("email", "==", inviteEmail)
        .get()
        .then((snapshots) => {
          if (snapshots.size) {
            isValid = false;
            message.error("You already invited this user. Please try again.");
          }
        });
    }
    if (isValid) {
      members.push({ email: inviteEmail });

      await firestore
        .collection("customers")
        .doc(ownerId)
        .collection("team_members")
        .doc(ownerSubscription)
        .collection("members")
        .add({ email: inviteEmail })
        .then(() => {
          message.success("New user has been added to your team successfully.");
          this.setState({
            inviteEmail: "",
            isInvitePopupVisible: false,
          });
        })
        .catch((error) => message.error(error.message));
    }
  };
  onUpdateSubscription = async () => {
    const functionRef = firebaseApp
      .functions("us-central1")
      .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
  };
  onRemoveTeamMember = (member) => {
    this.setState({
      selectedMember: { ...member },
      isConfirmRemovePopupVisible: true,
    });
  };
  onConfirmRemove = () => {
    const { ownerId, ownerSubscription } = this.state;
    const { key, email } = this.state.selectedMember;

    firestore
      .collection("customers")
      .doc(ownerId)
      .collection("team_members")
      .doc(ownerSubscription)
      .collection("members")
      .doc(key)
      .delete()
      .then(() => {
        this.setState({ isConfirmRemovePopupVisible: false });
        message.success(
          "Member(" + email + ") has been removed from team successfully."
        );
      })
      .catch((error) => message.error(error.message));
  };
  onCancelRemove = () => {
    this.setState({ isConfirmRemovePopupVisible: false });
  };
  render() {
    const subscriptionColumns = [
      {
        title: "Subscription",
        dataIndex: "name",
        key: "name",
        width: 150,
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        width: 150,
        render: (record) => {
          return record === 0 ? "Free" : "$" + record + "/month";
        },
      },
      {
        title: "Action",
        key: "action",
        width: 160,
        class: "gx-text-center",
        fixed: "right",
        render: (text, record) => {
          return (
            <Button
              className="gx-mb-0"
              type="success"
              onClick={this.onUpdateSubscription}
            >
              Update
            </Button>
          );
        },
      },
    ];
    const invitedUsersColumns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 150,
      },
      {
        title: "Action",
        key: "action",
        width: 160,
        class: "gx-text-center",
        fixed: "right",
        render: (text, record) => {
          return (
            <>
              <Button
                className="gx-mb-0"
                type="danger"
                onClick={() => this.onRemoveTeamMember(record)}
              >
                Remove
              </Button>
            </>
          );
        },
      },
    ];
    return (
      <>
        <Card title="User subscription">
          <Table
            className="gx-table-responsive"
            bordered={true}
            columns={subscriptionColumns}
            dataSource={this.state.subscriptions}
          />
        </Card>
        {this.state.userSubscription.price !== 0 ? (
          <Card
            title={
              "Add People To Your Team ( Available: " +
              this.state.teamUsers.length +
              ", Total: " +
              this.state.teamUsersLimit +
              " )"
            }
            extra={
              <Button
                className="gx-mb-0"
                type="success"
                onClick={() => this.setisInvitePopupVisible(true)}
              >
                Invite
              </Button>
            }
          >
            <Table
              className="gx-table-responsive"
              bordered={true}
              columns={invitedUsersColumns}
              dataSource={this.state.teamUsers}
            />
            <Modal
              title="Invite people to a team"
              wrapClassName="vertical-center-modal"
              visible={this.state.isInvitePopupVisible}
              onOk={this.onSendInvitation}
              onCancel={() => this.setisInvitePopupVisible(false)}
            >
              <Input
                type="email"
                placeholder="Email"
                value={this.state.inviteEmail}
                onChange={this.onInviteEmailChanged}
              />
            </Modal>

            <SweetAlert
              show={this.state.isConfirmRemovePopupVisible}
              warning
              showCancel
              confirmBtnText={<IntlMessages id="sweetAlerts.yesDeleteIt" />}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={<IntlMessages id="sweetAlerts.areYouSure" />}
              onConfirm={() => this.onConfirmRemove()}
              onCancel={this.onCancelRemove}
            ></SweetAlert>
          </Card>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default Subscriptions;
